// ********************************************************************************************************************
// ********************************* Choose rental date range before selecting item **********************************
// ********************************************************************************************************************

import React, { Component } from "react";
import {
  Grid,
  Header,
  Form,
  Button,
  Select,
  Popup,
  Icon,
} from "semantic-ui-react";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { commonFunctions } from "../../../../../../functional/global-import";

const CustomTimefirst = [
  { key: "01", value: "01", text: "01" },
  { key: "02", value: "02", text: "02" },
  { key: "03", value: "03", text: "03" },
  { key: "04", value: "04", text: "04" },
  { key: "05", value: "05", text: "05" },
  { key: "06", value: "06", text: "06" },
  { key: "07", value: "07", text: "07" },
  { key: "08", value: "08", text: "08" },
  { key: "09", value: "09", text: "09" },
  { key: "10", value: "10", text: "10" },
  { key: "11", value: "11", text: "11" },
  { key: "12", value: "12", text: "12" },
];

const CustomTimetwo = [
  { key: "00", value: "00", text: "00" },
  { key: "05", value: "05", text: "05" },
  { key: "10", value: "10", text: "10" },
  { key: "15", value: "15", text: "15" },
  { key: "20", value: "20", text: "20" },
  { key: "25", value: "25", text: "25" },
  { key: "30", value: "30", text: "30" },
  { key: "35", value: "35", text: "35" },
  { key: "40", value: "40", text: "40" },
  { key: "45", value: "45", text: "45" },
  { key: "50", value: "50", text: "50" },
  { key: "55", value: "55", text: "55" },
];

const CustomTimethree = [
  { key: "am", value: "am", text: "AM" },
  { key: "pm", value: "pm", text: "PM" },
];
class RentalDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      dateTime: this.props.currentDay,
      dateTime2: "",
      datesRange: "",
      count: 1,
      Adultcount: 1,
    };
  }
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
      localStorage.setItem("availableItemDate" , value)
    }
  };
  countHandleChange = () => {
    const { count, Adultcount } = this.state;
    this.setState({ count: count + 1 });
    this.setState({ Adultcount: Adultcount + 1 });
  };
  countHandleChangeMinus = () => {
    const { count } = this.state;
    if (count >= 1) {
      this.setState({ count: count - 1 });
    }
  };
  onContinueClick = () => {
    const { orderInventoryStateRef } = this.props;
    let date = this.state.dateTime.split(" - ");
    let startDate = `${date[0]} ${orderInventoryStateRef.state.startTime}`;
    let endDate = `${date[1] ? date[1] : date[0]} ${
      orderInventoryStateRef.state.endTime
    }`;
    const startDateHour = moment(startDate, "MM-DD-YYYY, hh:mm A").format("HH");
    const startDateMinute = moment(startDate, "MM-DD-YYYY, hh:mm A").format(
      "mm"
    );
    const endDateHour = moment(endDate, "MM-DD-YYYY, hh:mm A").format("HH");
    const endDateMinute = moment(endDate, "MM-DD-YYYY, hh:mm A").format("mm");
    startDate = new Date(moment(date[0], "MM-DD-YYYY").format("MM/DD/YYYY"));
    startDate.setHours(startDateHour, startDateMinute);
    endDate = new Date(
      moment(date[1] ? date[1] : date[0], "MM-DD-YYYY").format("MM/DD/YYYY")
    );
    endDate.setHours(endDateHour, endDateMinute);
    let isDateValid = endDate > startDate;
    this.props.onContinueClick(this.state.dateTime);
    // if (isDateValid) {
    // } 
    
    // else {
    //   orderInventoryStateRef.props.actions.showNotification(
    //     {
    //       title: "Error",
    //       message: "End date/time must be greater than start date/time",
    //       position: "br",
    //       autoDismiss: commonFunctions.notificationTime(),
    //     },
    //     "error"
    //   );
    // }

  };
  render() {
    const { dateTime } = this.state;
    const { bookingSlot, onTimeHandleChange, currentDateFromApi } = this.props;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column width={8}>
            <Form>
              <DatesRangeInput
                name="dateTime"
                // minDate={moment().toDate()}
                minDate={
                  currentDateFromApi === undefined
                    ? moment().toDate()
                    : currentDateFromApi
                }
                placeholder="Select Start Date"
                value={dateTime}
                initialDate={dateTime}
                dateFormat={"MM-DD-YYYY"}
                iconPosition="right"
                label="Rental Period (From)"
                onChange={this.handleChange}
                closable
                inline
              />
            </Form>
          </Grid.Column>

          <Grid.Column width={8}>
            <Header as="h3" className="orange-color">
              Select date from calendar
            </Header>
            <p className="deepviolet-color mbt">
              Selected Dates : <strong>{this.state.dateTime}</strong>
            </p>
            <Grid.Column width={16} className="rightAllign">
              <Popup
                content="You can change end time to open after selecting item."
                trigger={<Icon name="info circle" className="orange-color" />}
              />
            </Grid.Column>
            {/* ========================= */}
            <Grid>
              <Grid.Column width={16}>
                <Header as="h2" className="maroon-color">
                  Start Time
                </Header>
              </Grid.Column>
              <Grid.Column width={4}>
                <Select
                  options={CustomTimefirst}
                  value={bookingSlot.startTimeHour}
                  className="custom-select"
                  name="startTimeHour"
                  fluid
                  onChange={onTimeHandleChange}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Select
                  options={CustomTimetwo}
                  value={bookingSlot.startTimeMinute}
                  className="custom-select"
                  name="startTimeMinute"
                  fluid
                  onChange={onTimeHandleChange}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Select
                  options={CustomTimethree}
                  value={bookingSlot.startTimeAm}
                  className="custom-select"
                  name="startTimeAm"
                  defaultValue="am"
                  fluid
                  onChange={onTimeHandleChange}
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <Header as="h2" className="maroon-color">
                  End Time
                </Header>
              </Grid.Column>
              <Grid.Column width={4}>
                <Select
                  value={bookingSlot.endTimeHour}
                  options={CustomTimefirst}
                  name="endTimeHour"
                  className="custom-select"
                  defaultValue="9"
                  fluid
                  onChange={onTimeHandleChange}
                  checked={this.state.value === "custom"}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Select
                  value={bookingSlot.endTimeMinute}
                  options={CustomTimetwo}
                  name="endTimeMinute"
                  className="custom-select"
                  defaultValue="00"
                  fluid
                  onChange={onTimeHandleChange}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Select
                  value={bookingSlot.endTimeAm}
                  options={CustomTimethree}
                  name="endTimeAm"
                  className="custom-select"
                  defaultValue="pm"
                  fluid
                  onChange={onTimeHandleChange}
                />
              </Grid.Column>
            </Grid>
            {/* ========================= */}
            <div className="rentalDateButton">
              <Button
                className="blue-btn"
                type="button"
                onClick={this.props.onBackBtnClick}
              >
                {" "}
                Back{" "}
              </Button>
              <Button
                className="orange-btn"
                type="button"
                disabled={dateTime === ""}
                onClick={this.onContinueClick}
              >
                {" "}
                Continue
              </Button>
            </div>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
export default RentalDate;
